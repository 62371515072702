import React from "react";
import { Link, graphql } from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ReactMarkdown from "react-markdown";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import WeddingMap from "../components/weddingmap";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import BoundsGeoJson from "../geo/bounds.json";

import "./things-to-do.scss";

const ThingsToDoPage = ({ data }) => {
  const GeoJSON = require("ol/format/GeoJSON").default;
  const projectionGet = require("ol/proj").get;

  let _boundsFeatures = new GeoJSON().readFeatures(BoundsGeoJson, {
    dataProjection: projectionGet("EPSG:4326"),
    featureProjection: projectionGet("EPSG:3857")
  });

  let bounds = {
    sanFrancisco: null,
    alongTheWay: null,
    mendocino: null
  };

  _boundsFeatures.forEach(x => {
    switch (x.get('name')) {
    case 'sf_bounds':
      bounds['sanFrancisco'] = x;
      break;
    case 'alongtheway_bounds':
      bounds['alongTheWay'] = x;
      break;
    case 'mendocino_bounds':
      bounds['mendocino'] = x;
      break;
    }
  });

  let poiComponents = {
    sanFrancisco: [],
    alongTheWay: [],
    mendocino: []
  };

  let pois = {
    sanFrancisco: [],
    alongTheWay: [],
    mendocino: []
  };

  let getPoiLayout = poi => {
    switch (poi.layout) {
      case "left":
      default:
        return (
          <Row className="thing-to-do" key={poi.name}>
            <Col className="col-12 col-md-8">
              <hr />
              <p>
                <b>{poi.name}</b>
              </p>
              <ReactMarkdown source={poi.description} />
            </Col>
            <Col className="col-12 col-md-4 photo-container">
              <div
                className="things-to-do-item-image"
                style={{ backgroundImage: "url(" + poi.photo + ")" }}
              />
            </Col>
        </Row>
        );
        break;

      case "right":
        return (
          <Row className="thing-to-do" key={poi.name}>
            <Col className="col-12 col-md-4 photo-container">
              <div
                className="things-to-do-item-image"
                style={{ backgroundImage: "url(" + poi.photo + ")" }}
              />
            </Col>
            <Col className="col-12 col-md-8">
              <hr />
              <p>
                <b>{poi.name}</b>
              </p>
              <ReactMarkdown source={poi.description} />
            </Col>
          </Row>
        );
        break;
    }
  };

  data.file.childPagesYaml.pois.map(poi => {
    switch (poi.section) {
      case "San Francisco":
        pois.sanFrancisco.push(poi);
        poiComponents.sanFrancisco.push(getPoiLayout(poi));
        break;
      case "Along the Way":
        pois.alongTheWay.push(poi);
        poiComponents.alongTheWay.push(getPoiLayout(poi));
        break;
      case "Mendocino":
        pois.mendocino.push(poi);
        poiComponents.mendocino.push(getPoiLayout(poi));
        break;
    }
  });

  return (
    <Layout page="things-to-do">
      <SEO title="Things To Do" />

      <PageHeader title={data.file.childPagesYaml.title} />

      <Row className="page-content section-header">
        <Col sm />
        <Col className="col-12 col-md-10">
          <h3>San Francisco</h3>
          <p>{data.file.childPagesYaml.sanFrancisco}</p>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col className="col-md-1" />
        <Col className="col-12 col-md-5 order-md-3 map-column">
          <WeddingMap mapid={"sf"} pois={pois.sanFrancisco} bounds={bounds.sanFrancisco} />
        </Col>
        <Col className="col-12 col-md-6 order-md-2">
          {poiComponents.sanFrancisco}
        </Col>
      </Row>

      <PageDivider />

      <Row className="page-content section-header">
        <Col sm />
        <Col className="col-12 col-md-10">
          <h3>Along the Way</h3>
          <p>{data.file.childPagesYaml.alongTheWay}</p>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col className="col-md-1" />
        <Col className="col-12 col-md-5 order-md-3 map-column">
          <WeddingMap mapid={"alongtheway"} pois={pois.alongTheWay} bounds={bounds.alongTheWay}/>
        </Col>
        <Col className="col-12 col-md-6 order-md-2">
          {poiComponents.alongTheWay}
        </Col>
      </Row>

      <PageDivider />

      <Row className="page-content section-header">
        <Col sm />
        <Col className="col-12 col-md-10">
          <h3>Mendocino</h3>
          <p>{data.file.childPagesYaml.mendocino}</p>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col className="col-md-1" />
        <Col className="col-12 col-md-5 order-md-3 map-column">
          <WeddingMap mapid={"mendocino"} pois={pois.mendocino} bounds={bounds.mendocino} />
        </Col>
        <Col className="col-12 col-md-6 order-md-2">
          {poiComponents.mendocino}
        </Col>
      </Row>

      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "pages/things-to-do.yml" }) {
      childPagesYaml {
        title
        description
        sanFrancisco
        alongTheWay
        mendocino
        pois {
          name
          visible
          layout
          section
          description
          link
          section
          photo
          location
        }
      }
    }
  }
`;

export default ThingsToDoPage;
